import React, { useLayoutEffect, useEffect, useState } from 'react';
import { Footer } from "../../Components/Footer"
import { Header } from "../../Components/Header"

import HempProd1 from "../../assets/new_prods/packaging/PackagingSTCover1.png";
import HempMProd1 from "../../assets/new_prods/packaging/PackagingSTMCover1.png";
import HempProd2 from "../../assets/new_prods/packaging/PackagingSTItem1.png";
import HempProd3 from "../../assets/new_prods/packaging/PackagingSTItem2.png";

import laislaProd1 from "../../assets/new_prods/packaging/PackagingNDCover1.png"
import laislaMProd1 from "../../assets/new_prods/packaging/PackagingNDMCover1.png"
import laislaProd2 from "../../assets/new_prods/packaging/PackagingNDItem1.png"
import laislaProd3 from "../../assets/new_prods/packaging/PackagingNDItem2.png"

import ultimaProd1 from "../../assets/new_prods/packaging/PackagingTHCover1.png"
import ultimaMProd1 from "../../assets/new_prods/packaging/PackagingTHMCover1.png"
import ultimaProd2 from "../../assets/new_prods/packaging/PackagingTHItem1.png"
import ultimaProd3 from "../../assets/new_prods/packaging/PackagingTHItem2.png"

import "./services.css"
import { ServiceSection } from "../../Components/services/ServiceSection";
import {  useLocation } from "react-router-dom";
const packageProsData = [
  {
    tagName: 'HempMe',
    bannerImg: HempProd1,
    mobileBanner: HempMProd1,
    leftImg: HempProd2,
    rightImg: HempProd3,
    brief: `HempMe, a natural hemp tea brand, sought a logo and packaging design. Their vision was to convey the product's purity and natural essence. Our goal was to create a visually appealing and informative packaging that communicated the health benefits and uniqueness of their hemp tea.`,
    process: `The logo was designed to reflect purity and nature, incorporating organic shapes and earthy tones. For the packaging, we combined clean, minimalistic design elements with subtle hemp motifs, emphasizing the product’s natural essence. Informative and visually engaging, each detail was crafted to resonate with health-conscious consumers and establish a cohesive and visually striking brand image that connects with the product's natural qualities.`,
    process1: '',
    review: `“Thrilled with the logo and packaging design for HempMe! The team brilliantly captured our vision, conveying the pure and natural essence of our hemp tea. The visually appealing packaging not only stands out in the market but effectively communicates the health benefits and uniqueness of our product. Exceptional work!”`,
    reviewUser: `Amy. G`,
    reserved: false,
  },
  {
    tagName: 'La Isla',
    bannerImg: laislaProd1,
    mobileBanner: laislaMProd1,
    leftImg: laislaProd2,
    rightImg: laislaProd3,
    brief: `La Isla Beauty needed packaging design for their cosmetics company. A women-run business in Topeka, Kansas, they aimed to be a trusted brand for global customers seeking quality hair care and cosmetics, emphasising the importance of feeling beautiful!`,
    process: 'The packaging design drew inspiration from the brand’s ethos, featuring vibrant yet elegant visuals that conveyed confidence and inclusivity. A cohesive color palette and modern typography were chosen to enhance the packaging’s appeal while maintaining a professional aesthetic.',
    process1: '',
    review: `“Great work laconic! I will hire again.”`,
    reviewUser: `Amelia T.`,
    reserved: true,
  },
  {
    tagName: 'ULTIMA BREWERY',
    bannerImg: ultimaProd1,
    mobileBanner: ultimaMProd1,
    leftImg: ultimaProd2,
    rightImg: ultimaProd3,
    brief: `Ultima, a craft beer brewery in Romania specialising in experimental beers, sought unique labels for two brews: "Bonjour Berlin" (Italian Caprese flavoured beer) & "Some Art" (fruited sour beer).`,
    process: `We translated their vision into two distinctive designs. For "Bonjour Berlin", we seamlessly combined Italian, German, and French elements, incorporating caprese colours, bauhaus shapes, and bespoke fonts. For "Some Art", an instantly-recognisable, highly stylised, minimal design. The final labels reflected Ultima's fun experimental spirit and provided highly unique visual identities for their two beers.`,
    process1: '',
    review: `“Since it was my first project with a graphic designer I didn't know, I was a bit worried. But working with laconic. for two of my beer labels was an excellent experience. Fast understanding of the project, good communication and most of all, an end result that I still love. Totally recommended!”`,
    reviewUser: `Lucian C`,
    reserved: false,
  },
]

export const Packaging = () => {
  let location = useLocation();

  useEffect(() => {
    if(location.hash){
      const viewElement = document.getElementById(`pageNum${location.hash}`)
      viewElement.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
    }else{
      window.scrollTo(0, 0)
    }
   }, [])

  return (
    <div className="service-pages bg-[#0e0e0e] py-2 md:pt-4 lg:pt-4 md:pb-32 lg:pb-32">
      <Header />
      {packageProsData.map((packageData, key) => (
        <ServiceSection 
          key={key}
          pageIndex={key}
          isScrollAnimation={key !== 0} reserved={packageData.reserved}
          mobileBanner={packageData.mobileBanner}
          bannerImg={packageData.bannerImg} prod1={packageData.leftImg} prod2={packageData.rightImg}
          tagName={packageData.tagName} brief={packageData.brief} process={packageData.process} 
          process1={packageData.process1} review={packageData.review} reviewuser={packageData.reviewUser}
          />
      ))}
      <div className='w-full py-20'></div>
      <Footer />
    </div>
  )
}