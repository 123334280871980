import React, { useLayoutEffect, useEffect, useState } from 'react';
import { Footer } from "../../Components/Footer"
import { Header } from "../../Components/Header"

import SoulProd1 from "../../assets/new_prods/illustration/illustrationSTCover1.png";
import SoulMProd1 from "../../assets/new_prods/illustration/illustrationSTMCover1.png";
import SoulProd2 from "../../assets/new_prods/illustration/illustrationSTItem1.png";
import SoulProd3 from "../../assets/new_prods/illustration/illustrationSTItem2.png";

import AnimalProd1 from "../../assets/new_prods/illustration/illustrationNDCover1.png"
import AnimalMProd1 from "../../assets/new_prods/illustration/illustrationNDMCover1.png"
import AnimalProd2 from "../../assets/new_prods/illustration/illustrationNDItem1.png"
import AnimalProd3 from "../../assets/new_prods/illustration/illustrationNDItem2.png"

import AssortedProd1 from "../../assets/new_prods/illustration/illustrationTHCover1.png"
import AssortedProd2 from "../../assets/new_prods/illustration/illustrationTHItem1.png"
import AssortedProd3 from "../../assets/new_prods/illustration/illustrationTHItem2.png"

import "./services.css"
import { ServiceSection } from "../../Components/services/ServiceSection";
import { useLocation } from 'react-router-dom';

const packageProsData = [
  {
    tagName: 'Soul Masters TCG',
    bannerImg: SoulProd1,
    mobileBanner: SoulMProd1,
    leftImg: SoulProd2,
    rightImg: SoulProd3,
    brief: `To create ethereal, fantasy-based anime characters for the trading card game "Soul Masters." Characters embodied a captivating, otherworldly essence to enhance the game's magical theme.`,
    process: 'We conceptualised ethereal character designs, presenting sketches for client feedback. We refined the chosen concepts into detailed illustrations aligned with the "Soul Masters" aesthetic.',
    process1: `Finally, we delivered high-quality, unique anime character artwork, ensuring each character embodied the fantasy and magic essential to the trading card game's enchanting world.`,
    review: `When I started my project, one of my biggest problems was finding the right artist to portray my vision. I went through a dozen and none met my expectations, I was getting frustrated. When I met laconic and gave them some samples and ideas to portray, it was as if they understood what I wanted, and I could finally breathe. I’ve been working with them for over a year and all I can say is thank you from the bottom of my heart, for finally giving life to my project!`,
    reviewUser: `Pedro A.`,
    reserved: false,
  },
  {
    tagName: 'VARIOUS',
    bannerImg: AnimalProd1,
    mobileBanner: AnimalMProd1,
    leftImg: AnimalProd2,
    rightImg: AnimalProd3,
    brief: `We can produce diverse illustrations, portraits, characters, icons and infographics and deliver visually compelling assets that resonate with our clients target audience.`,
    process: `Starting with a comprehensive understanding of the client's vision, we utilise our skills and software to bring the concepts to life. Iterative feedback sessions and revisions ensure the illustrations meet the clients expectations.`,
    process1: '',
    review: `Our agency's diverse illustration portfolio showcases unparalleled creativity and client-focused excellence. From vibrant brand imagery to captivating editorial illustrations, each piece reflects our commitment to exceeding expectations. With a keen eye for detail and a passion for visual storytelling, we consistently deliver compelling visuals that elevate our clients' visions.`,
    reviewUser: ``,
    reserved: true,
  },
  {
    
    tagName: 'PEE-CHEE',
    bannerImg: AssortedProd1,
    mobileBanner: AssortedProd2,
    leftImg: AssortedProd2,
    rightImg: AssortedProd3,
    brief: `The project involved designing a creative, nostalgic reimagining of the classic Pee-Chee folder, customised to reflect the unique identity of the client’s company. The objective was to replace the original folder’s characters with illustrations of the client’s team members performing tasks and poses relevant to their specific roles. `,
    process: `Conducted a detailed study of the classic Pee-Chee folder, noting its visual elements such as layout, typography, line art, and colour schemes. We then gathered insights about their team members, their roles, and potential poses or actions that best represent their contributions to the company.`,
    process1: '',
    review: `Highly talented individuals will definitely use them in the future! 5*`,
    reviewUser: `Baruch S.`,
    reserved: false,
  },
  
]

export const Illustration = () => {
  let location = useLocation();

  useEffect(() => {
    if(location.hash){
      const viewElement = document.getElementById(`pageNum${location.hash}`)
      viewElement.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
    }else{
      window.scrollTo(0, 0)
    }
   }, [])

    return (
        <div className="service-pages bg-[#0e0e0e] py-2 md:pt-4 lg:pt-4 md:pb-32 lg:pb-32">
          <Header />
          {packageProsData.map((packageData, key) => (
            <ServiceSection 
            key={key}
            pageIndex={key}
            isScrollAnimation={key !== 0} reserved={packageData.reserved}
            mobileBanner={packageData.mobileBanner}
            bannerImg={packageData.bannerImg} prod1={packageData.leftImg} prod2={packageData.rightImg}
            tagName={packageData.tagName} brief={packageData.brief} process={packageData.process} 
            process1={packageData.process1} review={packageData.review} reviewuser={packageData.reviewUser}
            />
          ))}
          <div className='w-full py-20'></div>
          <Footer />
        </div>
      )
}