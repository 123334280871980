import React, { useLayoutEffect, useRef, useState } from 'react';
import { Box, Container, Stack } from "@mui/material"
import {isMobile} from 'react-device-detect';

export const ServiceSection = ({pageIndex, tagName, brief, process, process1, review, reviewuser, bannerImg, mobileBanner, prod1, prod2, isScrollAnimation, reserved}) => {
  const main = useRef();
  
  return (
    <section id={`pageNum#${pageIndex}`} ref={main} className={`service-section pb-10 pt-24 md:pt-40 lg:pt-40 px-2`}>
      <Container maxWidth="xl">
        <div className={`w-full max-w-[1470px] mx-auto `} >
          <div className="service-banner animate-fadeIn aspect-square md:aspect-[1470/800] lg:aspect-[1470/800]" style={{background: `url(${isMobile? mobileBanner : bannerImg})`}}>
            <img src={isMobile? mobileBanner : bannerImg} className='w-full h-full rounded-2xl'/>
            <div className="service-title pr-4 pb-2 md:pr-8 md:pb-4 lg:pr-8 lg:pb-4 text-[15pt] md:text-[22pt] lg:text-[22pt]">
              <div className={isScrollAnimation? "relative z-10 videobg-text animate-fadeIn scroll-start-animation" : "relative z-10 animate-fadeIn videobg-text"}>
                {tagName}
              </div> 
            </div>
            <div className="service-detail text-ceter px-4 py-2 md:text-left md:px-7 md:py-4 lg:text-left lg:px-7 lg:py-4" >
              <div className={isScrollAnimation?"service-detail-content animate-fadeIn scroll-end-animation":"service-detail-content animate-fadeIn"}>
                <h4 className="service-detail-h4 text-[14pt] md:text-[26pt] lg:text-[26pt] font-extralight uppercase">
                  brief
                </h4>
                <p className="service-detail-text text-[5pt] md:text-[8pt] lg:text-[8pt] font-extralight">
                  {brief}
                </p>
              </div>
            </div> 
          </div>
          <div className="hidden md:block lg:block service-prods pt-16">
            <Stack direction={"row"} flexDirection={reserved? "row-reverse":"row"} gap={10}>
              <Box display={"flex"} flexDirection={"column"} position={"relative"} flex={1} gap={4}>
                <img src={prod1} className="rounded-xl animate-fadeIn w-full"/>
                <Box className="animate-fadeIn scroll-end-animation w-full h-full"  flexDirection={'column'} justifyContent={'center'} display={'flex'} sx={{color: "white", textAlign: "left"}}>
                  <h4 className="service-detail-h4 text-[15pt] md:text-[26pt] lg:text-[26pt] font-extralight uppercase">
                    review
                  </h4>
                  <p className="service-detail-text text-[5pt] md:text-[8pt] lg:text-[8pt] font-extralight py-3">
                    {review}
                  </p>
                  <div className="pt-2">
                    <h5 className="text-[5pt] md:text-[8pt] lg:text-[8pt] font-semibold">{reviewuser}</h5>
                    <div className="flex justify-start items-center">
                      <div className="star_5 text-[30px]"></div>
                    </div>
                  </div>
                </Box>
              </Box>
              <Box display={"flex"} flexDirection={"column-reverse"} position={"relative"} flex={1} gap={4}>
                <img src={prod2} className="rounded-xl w-full aspect-square"/>
                <Box  className="animate-fadeIn scroll-start-animation w-full h-full" sx={{ color: "white", textAlign: "left", }}>
                  <h4 className="service-detail-h4 text-[14pt] md:text-[26pt] lg:text-[26pt] font-extralight uppercase">
                    process
                  </h4>
                  <p className="service-detail-text text-[5pt] md:text-[8pt] lg:text-[8pt] font-extralight py-3">
                    {process}
                  </p>
                  <p className="service-detail-text text-[5pt] md:text-[8pt] lg:text-[8pt] font-extralight py-3">
                    {process1}
                  </p>
                </Box>
              </Box>
            </Stack>
          </div>
          <div className='block md:hidden lg:hidden pt-6'>
            <Stack direction={'column'} flexDirection='column' gap={3}>
              <Box display={'flex'} gap={2} justifyContent={'center'} alignItems={'center'} >
                <Box flex={1} className="animate-fadeIn scroll-start-animation w-full h-full" sx={{ color: "white", textAlign: "center"}}>
                  <h4 className="service-detail-h4 text-[14pt] md:text-[26pt] lg:text-[26pt] font-extralight uppercase">
                    process
                  </h4>
                  <p className="service-detail-text text-[5pt] md:text-[8pt] lg:text-[8pt] font-extralight py-3">
                  {process}
                  </p>
                </Box>
                <Box  flex={2} className="w-full">
                  <img src={prod1} className="rounded-xl  animate-fadeIn"/>
                </Box>
              </Box>
              <Box display={'flex'} gap={2}  justifyContent={'center'} alignItems={'center'} >
                <Box  flex={2} className="w-full">
                  <img src={prod2} className="rounded-xl  animate-fadeIn"/>
                </Box>
                <Box flex={1} className="animate-fadeIn scroll-end-animation w-full h-full" sx={{ color: "white", textAlign: "center", }}>
                  <h4 className="service-detail-h4 text-[15pt] md:text-[26pt] lg:text-[26pt] font-extralight uppercase">
                    review
                  </h4>
                  <p className="service-detail-text text-[5pt] md:text-[8pt] lg:text-[8pt] font-extralight py-3">
                    {review}
                  </p>
                  <div className="pt-2">
                    <h5 className="text-[5pt] md:text-[8pt] lg:text-[8pt] font-semibold">{reviewuser}</h5>
                    <div className="flex justify-start items-center">
                      <div className="sm_start_5"></div>
                    </div>
                  </div>
                </Box>
              </Box>
            </Stack>
          </div>
        </div>
      </Container>
      
    </section>
    
  )
}