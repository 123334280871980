import React, { useLayoutEffect, useEffect, useState } from 'react';
import { Footer } from "../../Components/Footer"
import { Header } from "../../Components/Header"

import HawkeyeProd1 from "../../assets/new_prods/present/presentSTCover1.png";
import HawkeyeMProd1 from "../../assets/new_prods/present/presentSTMCover1.png";
import HawkeyeProd2 from "../../assets/new_prods/present/presentSTItem1.png";
import HawkeyeProd3 from "../../assets/new_prods/present/presentSTItem2.png";

import DogmaProd1 from "../../assets/new_prods/present/presentNDCover1.png";
import DogmaMProd1 from "../../assets/new_prods/present/presentNDMCover1.png";
import DogmaProd2 from "../../assets/new_prods/present/presentNDItem1.png";
import DogmaProd3 from "../../assets/new_prods/present/presentNDItem2.png";

import ForgeProd1 from "../../assets/new_prods/present/presentTHCover1.png"
import ForgeMProd1 from "../../assets/new_prods/present/presentTHMCover1.png"
import ForgeProd2 from "../../assets/new_prods/present/presentTHItem1.png"
import ForgeProd3 from "../../assets/new_prods/present/presentTHItem2.png"

import "./services.css"
import { ServiceSection } from "../../Components/services/ServiceSection";
import { useLocation } from 'react-router-dom';

const presentationData = [
  {
    tagName: 'EMERALD',
    bannerImg: HawkeyeProd1,
    mobileBanner: HawkeyeMProd1,
    leftImg: HawkeyeProd2,
    rightImg: HawkeyeProd3,
    brief: `Emerald, a business consulting firm specialising in consultation, required a bespoke internal presentation template to enhance consistency and professionalism in their internal communications, whilst being adaptable to various internal presentation needs, such as project updates, strategic planning, and team meetings.`,
    process: 'We worked closely with key team members to understand their presentation needs, preferences, and the types of content typically shared internally, incorporating Emerald’s branding elements to create a cohesive and professional look, reflecting the company’s identity.',
    process1: '',
    review: `“The alignment with the company’s brand identity has strengthened internal branding efforts, while the template’s versatility has streamlined the creation of various presentation types. Thank you laconic!”`,
    reviewUser: `Amy. B`,
    reserved: false,
  },
  {
    tagName: 'HAWKEYE',
    bannerImg: DogmaProd1,
    mobileBanner: DogmaMProd1,
    leftImg: DogmaProd2,
    rightImg: DogmaProd3,
    brief: `Tasked with developing a pitch deck for Hawkeye, a device designed to provide cyclists with a distraction-free view of what's behind them. The AI-driven rear camera detects potential threats and issues timely alerts for enhanced safety awareness.`,
    process: `Understanding Hawkeye’s innovative technology and its mission to enhance cyclist safety, the pitch deck was designed to highlight the device’s key features, including its AI-driven capabilities and distraction-free functionality, with a clean and modern aesthetic. We structured the deck to flow seamlessly, incorporating compelling visuals, concise messaging, and data-driven insights to emphasize Hawkeye’s value proposition. Custom graphics illustrated the rear camera’s functionality and safety benefits, ensuring the presentation resonated with potential investors and partners by showcasing the product’s impact and market potential.`,
    process1: '',
    review: `“laconic. did excellent work!!”`,
    reviewUser: `Peter C.`,
    reserved: false,
  },
  {
    tagName: 'DOGMA MEDIA',
    bannerImg: ForgeProd1,
    mobileBanner: ForgeMProd1,
    leftImg: ForgeProd2,
    rightImg: ForgeProd3,
    brief: `Develop a compelling pitch deck for a brand content & innovation studio. The studio's vision involved crafting comprehensive content ecosystems to connect brands and audiences through engaging, meaningful content. They curate world-class teams dedicated to delivering optimal results for brands.`,
    process: `We commenced by thoroughly understanding the studio's mission and values. We translated their vision into a visually appealing pitch deck. Our process involved showcasing the studio's multidisciplinary approach, highlighting the curated pool of creators, and emphasising the unique value proposition for brands.`,
    process1: '',
    review: `“Your creativity blew me away, and I can't thank you enough for turning our ideas into a visual masterpiece. The way you seamlessly integrated our brand vibe into every nook and cranny of the plan was pure genius – it's like you read our minds! But what really stood out for me was your keen understanding of the impact visual language can have beyond the numbers. You didn't just design a document; you crafted an experience. The extra mile you went in making sure our story isn't just told but felt is something I truly appreciate.”`,
    reviewUser: `Dave. A`,
    reserved: true,
  },
  
]

export const Presentation = () => {
  let location = useLocation();

  useEffect(() => {
    if(location.hash){
      const viewElement = document.getElementById(`pageNum${location.hash}`)
      viewElement.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
    }else{
      window.scrollTo(0, 0)
    }
   }, [])


  return (
    <div className="service-pages bg-[#0e0e0e] py-2 md:pt-4 lg:pt-4 md:pb-32 lg:pb-32">
      <Header />
      {presentationData.map((presentData, key) => (
        <ServiceSection 
        key={key}
        pageIndex={key}
        isScrollAnimation={key !== 0} reserved={presentData.reserved}
        mobileBanner={presentData.mobileBanner}
        bannerImg={presentData.bannerImg} prod1={presentData.leftImg} prod2={presentData.rightImg}
        tagName={presentData.tagName} brief={presentData.brief} process={presentData.process} 
        process1={presentData.process1} review={presentData.review} reviewuser={presentData.reviewUser}
        />
      ))}
      <div className='w-full py-20'></div>
      <Footer />
    </div>
  )
}