// import { Container } from "@mui/material"
import { Footer } from "../Components/Footer"
import { Header } from "../Components/Header"
import { useEffect, useRef } from "react"
// import BackgroundVideo from 'react-background-video-player';
import { ScrollSmoother } from "gsap/ScrollSmoother";
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import gsap from 'gsap';
import { useGSAP } from '@gsap/react';
import { SplitText } from 'gsap/all';
// import {isMobile} from 'react-device-detect';
import bgVideo from '../assets/bg.mp4';
// import { Swiper, SwiperSlide } from 'swiper/react';
// import { Autoplay, EffectFade, FreeMode } from 'swiper/modules';
// import 'swiper/css';

// import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import './aboutus.css';
import { reviewlist } from "../confdata";


gsap.registerPlugin(useGSAP, ScrollTrigger, ScrollSmoother, SplitText);
const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
    autoplay: true
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 5,
    autoplay: true
  },
  tablet: {
    breakpoint: { max: 1024, min: 800 },
    items: 2,
    autoplay: true
  },
  mobile: {
    breakpoint: { max: 800, min: 0 },
    items: 1,
    autoplay: false
  }
};

export const Aboutus = () => {
  const main = useRef();
  const videoRef = useRef();

  useGSAP(() => {
    gsap.to(".aboutus-title-span", {opacity: 0});
    var split = new SplitText(".aboutus-title-span", 
      {
        type: "words, chars",
        wordDelimiter: "*",
        wordsClass: "word"
    });
    
    gsap.fromTo(".aboutus-detail", { autoAlpha: 1, x: -1200 },
      { autoAlpha: 1, x: 0, duration: 0.5 }, 
    )
    
    gsap.fromTo(".aboutus-title", { autoAlpha: 1, x: -1200,
      onStart: () => {
      //  gsap.to(".aboutus-title-span", {opacity: 0});
      }
     },
      { autoAlpha: 1, x: 0, duration: 0.5,
        onComplete: () => {
          gsap.to(".aboutus-title-span", {opacity: 1});
          // gsap.set(split, {perspective:800});
          gsap.from(split.chars, {
            duration: 0.5, 
            autoAlpha: 1,
            y: 300, 
            
            stagger: 0.05,
            delay: 0.5,
          });
        }
       })
    
    var detailLines = new SplitText(".aboutus-detail", {
      type: "lines"
    })
    gsap.from(detailLines.lines, {
      opacity: 0,
      duration: 1, 
      x: -50, 
      y: 10,
      stagger: 0.1,
      delay: 2
    })

    var itallyText = new SplitText(".aboutus-bottom", {
      type: "lines"
    });
    gsap.from(itallyText.lines, {
      opacity: 0,
      duration: 1.5, 
      x: 50, 
      scale:1.2, 
      force3D:true,
      delay: 2
    });

    
  }, {
    scope: main
  })
useEffect(() => {
  var slidertimeline =  gsap.timeline();
  slidertimeline.set(".box", {
        x: (i) => i * 1
    });


  slidertimeline.to(".box", {
      duration: 40,
      ease: "none",
      x: "-=1000",
      modifiers: {
          x: gsap.utils.unitize(x => parseFloat(x) % 1000)
      },
      repeat: -1
  });
}, [])
  return (
    <div className="aboutus-page h-screen py-2 md:pt-4 lg:pt-4 md:pb-24 lg:pb-24 bg-[#0e0e0e]">
      <Header />
      <section  className="relative pt-20 md:pt-36 lg:pt-36 px-4 md:px-36 lg:px-36">
        <div className="w-full">
          <div  ref={main} className="hidden md:block lg:block relative w-full h-fit pt-8">
            {/* <BackgroundVideo 
              src={bgVideo}
              loop={true}
              autoPlay={true}
            /> */}
            <video ref={videoRef} className='absolute w-full h-full object-cover rounded-3xl' autoPlay playsInline loop muted >
              <source src={bgVideo} type="video/mp4"></source>
            </video>
            <div className="z-10 aboutus-title text-[150pt] leading-[160px] pb-3 uppercase font-semibold flex w-fit text-white bg-[#0e0e0e]">
              <span className="aboutus-title-span opacity-0 overflow-hidden">About us</span> 
            </div>
            
            <div className="z-10 grid grid-cols-12 gap-2">
              <div className="aboutus-detail col-span-5 text-[13pt] leading-[16pt] text-white text-start py-3 bg-[#0e0e0e] pr-4">
                <p className="font-bold pb-2">
                  Our agency embodies simplicity and sophistication.
                </p>
                <p className="py-2 text-left">
                  With a passion for visual storytelling, we transform ideas into impactful designs that resonate with clarity and purpose.
                </p>
                <p className="py-2 text-left">
                  At laconic, we believe in the power of minimalism to convey maximum impact, creating a visual language that speaks directly to the heart of your message.
                </p>
                <p className="py-2 text-left">
                  Join us on a journey where every pixel counts, and design is more than just an art, it's a powerful form of communication.
                </p>
              </div>
            </div>
            <div className="z-10 aboutus-bottom text-[18px] text-white italic absolute bottom-0 right-0 p-7 bg-[#0e0e0e] rounded-tl-3xl">
              Expressing much in few words
            </div>            
          </div>
          <div className="block md:hidden lg:hidden relative z-10 w-full h-full">
            <div className="text-[42pt] font-semibold uppercase text-center text-white">
              about us
            </div>
            <div className="text-[8pt] text-center text-white">
                <p className="py-2">
                  Our agency embodies simplicity and sophistication.
                </p>
                <p className="py-2 text-left">
                  With a passion for visual storytelling, we transform ideas into impactful designs that resonate with clarity and purpose.
                </p>
                <p className="py-2 text-left">
                  At laconic, we believe in the power of minimalism to convey maximum impact, creating a visual language that speaks directly to the heart of your message.
                </p>
                <p className="py-2 text-left">
                  Join us on a journey where every pixel counts, and design is more than just an art, it's a powerful form of communication.
                </p>
            </div>
            <div className="w-full h-16 py-5">
              {/* <BackgroundVideo 
                src={bgVideo}
                loop={true}
                autoPlay={true}
              /> */}
              <video className='w-full h-16 object-cover rounded-md' autoPlay playsInline loop muted >
                <source src={bgVideo} type="video/mp4"></source>
              </video>
            </div>
          </div>
        </div>
      </section>
      <section className="relative pt-8 md:pt-10 lg:pt-[60px] pb-14 px-8 md:px-28 lg:px-28 bg-[#0e0e0e]">
        <div className="w-full">
          <div className="slider">
            <div className="landing-wrapper">
                <div className="landing-inner-content">
                  {reviewlist.map((reviewItem, key) => (
                    <div key={key} className="box  box2">
                      <div className="box-content">
                        <div className="review-item h-full px-3 flex flex-col justify-between text-left text-white text-[13px]">
                          <div className="w-full">
                            <div className="star_5 text-[30px]"></div>
                            <div className="review-detail mt-2 px-1">
                              {reviewItem.detail}
                            </div>
                          </div>
                          
                          <div className="review-user px-1 mt-2 font-semibold">
                            {reviewItem.name}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
            </div>
          </div>
        </div>
        {/* <div className="w-full">
          <Swiper
            slidesPerView={1}
            spaceBetween={0}
            freeMode={{
              enabled: true,
              momentumBounce: false,
              momentum: false,
              sticky: false,
            }}
            pagination={{
              clickable: true,
            }}
            speed={10000}
            loop={true}
            loopAddBlankSlides={false}
            loopAdditionalSlides={10}
            grabCursor={true}
            observer={true}
            autoplay={{
              delay: 0,
              disableOnInteraction: false,
              stopOnLastSlide: false,
            }}
            modules={[Autoplay, FreeMode]}
            breakpoints={{
              // when window width is <= 499px
              499: {
                  slidesPerView: 1,
                  spaceBetweenSlides: 50
              },
              // when window width is <= 999px
              999: {
                  slidesPerView: 5,
                  spaceBetweenSlides: 50
              },
              // when window width is <= 2600px
              2600: {
                slidesPerView: 5,
                spaceBetweenSlides: 50
              }
            }
              
          }
          >
            {reviewlist.map((reviewItem, key) => (
              <SwiperSlide key={key}>
                <div className="review-item h-full px-3 flex flex-col justify-between text-left text-white text-[13px]">
                  <div className="w-full">
                    <div className="star_5 text-[30px]"></div>
                    <div className="review-detail mt-2 px-1">
                      {reviewItem.detail}
                    </div>
                  </div>
                  
                  <div className="review-user px-1 mt-2 font-semibold">
                    {reviewItem.name}
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div> */}
        {/* <Carousel responsive={responsive}
           additionalTransfrom={0}
           arrows={false}
           infinite={true}
           autoPlay={!isMobile}
           autoPlaySpeed={1}
           centerMode={false}
           className=""
           customTransition="all 5s linear"
           transitionDuration={5000}
        >
            {reviewlist.map((reviewItem, key) => (
              <div key={key} className="review-item px-1 h-full flex flex-col justify-between text-left text-white text-[13px]">
                <div className="w-full">
                  <div className="star_5 text-[30px]"></div>
                  <div className="review-detail mt-2 px-1 text-justify">
                    {reviewItem.detail}
                  </div>
                </div>
                
                <div className="review-user px-1 mt-2 font-semibold">
                  {reviewItem.name}
                </div>
              </div>
            ))}
        </Carousel> */}
      </section>
      <Footer />
    </div>
  )
}