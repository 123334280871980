import React, { useLayoutEffect,  useEffect, useState } from 'react';
import { Footer } from "../../Components/Footer"
import { Header } from "../../Components/Header"

import SearchlightProd1 from "../../assets/new_prods/media/mediaSTCover1.png";
import SearchlightMProd1 from "../../assets/new_prods/media/mediaSTMCover1.png";
import SearchlightProd2 from "../../assets/new_prods/media/mediaSTItem1.png";
import SearchlightProd3 from "../../assets/new_prods/media/mediaSTItem2.png";

import MellowtoneProd1 from "../../assets/new_prods/media/mediaNDCover1.png"
import MellowtoneMProd1 from "../../assets/new_prods/media/mediaNDMCover1.png"
import MellowtoneProd2 from "../../assets/new_prods/media/mediaNDItem1.png"
import MellowtoneProd3 from "../../assets/new_prods/media/mediaNDItem2.png"

import AssortedProd1 from "../../assets/new_prods/media/mediaTHCover1.png"
import AssortedMProd1 from "../../assets/new_prods/media/mediaTHMCover1.png"
import AssortedProd2 from "../../assets/new_prods/media/mediaTHItem1.png"
import AssortedProd3 from "../../assets/new_prods/media/mediaTHItem2.png"

import "./services.css"
import { ServiceSection } from "../../Components/services/ServiceSection";
import { useLocation } from 'react-router-dom';

const packageProsData = [
  {
    tagName: 'ASSORTED PRINT MEDIA',
    bannerImg: SearchlightProd1,
    mobileBanner: SearchlightMProd1,
    leftImg: SearchlightProd2,
    rightImg: SearchlightProd3,
    brief: `Our assorted print media projects cover a wide range of deliverables, such as brochures, flyers, posters, and other printed materials. Each piece was tailored to the unique needs of the client and designed to communicate key messages effectively while maintaining professional quality and aesthetic appeal.`,
    process: 'From concept development to execution, we focused on balancing impactful visuals with concise and clear messaging. By incorporating client feedback throughout the process, we refined each piece to deliver polished, high-quality results that were both visually engaging and print-ready.',
    process1: '',
    review: `From striking brochures to impactful flyers, each piece is designed with a focus on clarity, aesthetics, and strategic communication. By seamlessly blending compelling visuals with effective messaging, we ensure every project exceeds client expectations.`,
    reviewUser: ``,
    reserved: false,
  },
  {
    tagName: 'ATMOSKLEAR',
    bannerImg: MellowtoneProd1,
    mobileBanner: MellowtoneMProd1,
    leftImg: MellowtoneProd2,
    rightImg: MellowtoneProd3,
    brief: `Atmosklear, a company specialising in advanced air purification solutions, approached us to create a brochure that effectively highlighted the health benefits and innovative technology of their air purifiers. The objective was to present technical information in an approachable and visually appealing format to connect with both residential and commercial customers.`,
    process: 'The brochure’s layout was thoughtfully structured to guide readers through key product details, benefits, and applications, using atmospheric imagery and sleek typography to enhance readability. The final design was meticulously prepared for print, ensuring a professional finish that aligned with the company’s high standards.',
    process1: '',
    review: `The Atmosklear brochure is everything we hoped for—sleek, informative, and perfectly aligned with our brand. The team’s ability to distil technical details into a visually engaging format has been instrumental in showcasing our products effectively. We’re extremely satisfied with the results and highly recommend their services!`,
    reviewUser: `Julie. C`,
    reserved: true,
  },
  {
    tagName: 'O’PARI',
    bannerImg: AssortedProd1,
    mobileBanner: AssortedMProd1,
    leftImg: AssortedProd2,
    rightImg: AssortedProd3,
    brief: `O’Pari, a luxury yacht company, required a bespoke brochure to highlight their exquisite, handcrafted yachts. The goal was to create a high-end marketing piece that conveyed the elegance and sophistication of their brand, capturing the attention of discerning clients in the luxury market.`,
    process: `Each element of the design—from typography to layout—was curated to reflect the premium quality of their brand. The final piece was delivered with recommendations for high-end printing techniques, such as embossing and foil accents, to ensure the physical brochure matched the sophistication of the designs.`,
    process1: '',
    review: `The brochure is a masterpiece! It perfectly captures the sophistication and elegance of our yachts, exceeding all our expectations. The team’s attention to detail and understanding of our brand were outstanding. We’re thrilled with the outcome and proud to present this brochure to our clients.`,
    reviewUser: `Benny. R`,
    reserved: false,
  },
]

export const Visualmedia = () => {
  let location = useLocation();

  useEffect(() => {
    if(location.hash){
      const viewElement = document.getElementById(`pageNum${location.hash}`)
      viewElement.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
    }else{
      window.scrollTo(0, 0)
    }
   }, [])


    return (
        <div className="service-pages bg-[#0e0e0e] py-2 md:pt-4 lg:pt-4 md:pb-32 lg:pb-32">
          <Header />
          {packageProsData.map((packageData, key) => (
            <ServiceSection 
            key={key}
            pageIndex={key}
            isScrollAnimation={key !== 0} reserved={packageData.reserved}
            mobileBanner={packageData.mobileBanner}
            bannerImg={packageData.bannerImg} prod1={packageData.leftImg} prod2={packageData.rightImg}
            tagName={packageData.tagName} brief={packageData.brief} process={packageData.process} 
            process1={packageData.process1} review={packageData.review} reviewuser={packageData.reviewUser}
            />
          ))}
          <div className='w-full py-20'></div>
          <Footer />
        </div>
      )
}