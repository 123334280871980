import { Box, Container, Grid, Typography } from "@mui/material";
import './section.css';

import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useGSAP } from '@gsap/react';
import { ScrollSmoother } from 'gsap/ScrollSmoother';
import { useRef } from "react";
import {isMobile} from 'react-device-detect';
import prod3 from "../../assets/products/10135.png"
import prod4 from "../../assets/products/Group-3828.png"
import prod5 from "../../assets/products/Group-3502.png"
import { useNavigate } from "react-router-dom";

gsap.registerPlugin(useGSAP, ScrollTrigger, ScrollSmoother);

export const LandingRightSection = ({href, prodImg, prod2Img, prod3Img, title, subtitle, subDetail, detail}) => {
  const navigate = useNavigate();
  const main = useRef();
  const onClickExplore = (indexer) => {
    navigate(`${href}#${indexer}`);
  }
  return (
    <Container ref={main}>
      <Grid className={isMobile ? "" : "observerSec"} spacing={2} container justifyContent={'center'} alignItems={'center'} >
        <Grid item xs={12} md={7} lg={7} order={{ xs: 1, sm: 1 }}>
          {!isMobile && <Box className="relative w-full h-[740px] ">
						{/* <img src={prodImg} className="rounded-lg" alt='prod2' />  */}
            <div className="prod2 a z-30 absolute top-0 w-fit h-full rounded-2xl p-10 flex justify-center items-center">
              <div className="baseImage  relative w-fit h-fit rounded-2xl" style={{background: `url(${prodImg})`}}>
                <img src={prodImg} className="rounded-2xl opacity-0" alt='prod2' />   
                {/* <div onClick={() => {onClickExplore(0)}} className="z-50 cursor-pointer exploreAct absolute bottom-8 right-8 h-24 w-24 button--bestia">
                  <div className="button__bg"></div>
                  <span className=" cursor-pointer">Explore</span>
                </div> */}
              </div>
            </div>
            <div className="prod2 b z-20 absolute top-0 w-fit h-full rounded-2xl p-10 flex justify-center items-center">
              <div className="baseImage  relative w-fit h-fit rounded-2xl" style={{background: `url(${prod2Img})`}}>
                <img src={prod2Img} className="rounded-2xl opacity-0" alt='prod2' /> 
                {/* <div onClick={() => {onClickExplore(1)}} className="z-50 cursor-pointer exploreAct absolute bottom-8 right-8 h-24 w-24 button--bestia">
                  <div className="button__bg"></div>
                  <span className=" cursor-pointer">Explore</span>
                </div> */}
              </div>
            </div>
            <div className=" prod2 c z-10 absolute top-0 w-fit h-full rounded-2xl p-10 flex justify-center items-center">
              <div className="baseImage  relative w-fit h-fit rounded-2xl" style={{background: `url(${prod3Img})`}}>
                <img src={prod3Img} className="rounded-2xl opacity-0" alt='prod2' /> 
                {/* <div onClick={() => {onClickExplore(2)}} className="z-50 cursor-pointer exploreAct absolute bottom-8 right-8 h-24 w-24 button--bestia">
                  <div className="button__bg"></div>
                  <span className=" cursor-pointer">Explore</span>
                </div> */}
              </div>
            </div>
            {/* <div className="prod2 a absolute top-0 w-full h-full rounded-lg " style={{background: `url(${prodImg})`}}></div>
            <div className="prod2 a absolute top-0 w-full h-full rounded-lg " style={{background: `url(${prod2Img})`}}></div>
            <div className="prod2 b absolute top-0 w-full h-full rounded-lg" style={{background: `url(${prod3Img})`}}></div> */}
            {/* <img src={prod3} className="prod2 absolute w-full h-full" data-speed="0.5" alt='prod2'/> 
            <img src={prod4} className="prod2 absolute w-full h-full" data-speed="0.5" alt='prod2'/>  */}
						<div className="absolute top-0 w-fit h-full p-10 flex justify-center items-center">
              <div className="relative w-fit h-fit" >
                <img src={prodImg} className="rounded-lg opacity-0" alt='prod2' /> 
                {/* <div className="z-50 exploreAct absolute bottom-8 right-8 h-24 w-24 button--bestia">
                  <div className="button__bg"></div>
                  <span>Explore</span>
                </div> */}
                {/* <Box className="z-50 transition-all duration-500 [transform-style:preserve-3d] hover:[transform:rotateY(180deg)] exploreAct absolute bottom-8 right-8 h-24 w-24  ">
                  <Box className="w-full h-full flex justify-center items-center rounded-full text-white bg-[#000000b3] transition-all absolute front-flip">
                    <Typography>EXPLORE</Typography>
                  </Box>
                  <Box className="w-full h-full flex justify-center items-center rounded-full text-black bg-[#b3b3b3b3] transition-all absolute [transform:rotateY(180deg)] opacity-0 back-flip">
                    <Typography>EXPLORE</Typography>
                  </Box>
                </Box> */}
              </div>
            </div>
					</Box>}
          {isMobile && <Box className="relative w-full h-full" onClick={() => {onClickExplore(0)}}>
            <img src={prod2Img} className="rounded-2xl " alt='prod2' /> 
            <Box className="z-50 scale-75 origin-bottom-right transition-all duration-500 [transform-style:preserve-3d] absolute bottom-8 right-8 h-24 w-24">
              {/* <Box className=" cursor-pointer w-full h-full flex justify-center items-center rounded-full text-white bg-[#000000b3] transition-all absolute front-flip">
                <Typography className=" cursor-pointer">EXPLORE</Typography>
              </Box> */}
              {/* <Box className="w-full h-full flex justify-center items-center rounded-full text-black bg-[#b3b3b3b3] transition-all absolute [transform:rotateY(180deg)] opacity-0 back-flip">
                <Typography >EXPLORE</Typography>
              </Box> */}
            </Box>
          </Box>}
        </Grid>
        {!isMobile && <Grid item xs={12} md={5} lg={5} padding={4} className='text-white text-center md:text-start'  data-aos="fade-up"  data-aos-offset="500"
    data-aos-delay="100" data-aos-duration="1000" data-aos-easing="ease-in-out"  order={{ xs: 1, sm: 1 }}>
          <Box  marginBottom={2}>
            <Typography className='section-h3 text-[42.2pt]' variant='h4' fontWeight={800} textTransform={'uppercase'} color=''>
							{title}
						</Typography>
						<Typography variant='subtitle1' className="text-[15.8pt]" fontWeight={600}>
							{subtitle}
						</Typography>
						<Typography variant='subtitle2' className="text-[15.8pt]" fontWeight={400}>
							{subDetail}
						</Typography>
          </Box>
          <Box>
            <Typography variant='subtitle2' marginTop={2} fontWeight={400}>
            {detail}
            </Typography>
            <button onClick={() => {onClickExplore(0)}} className="touch-click w-full mt-8 py-2 px-14 text-[21pt] font-extralight">
              <span className="mix-blend-difference">
              Explore
              </span> 
            </button>
          </Box>
        </Grid>}
        {isMobile && <Grid item xs={12} md={5} lg={5} className='text-white text-center md:text-start'   order={{ xs: 1, sm: 1 }}>
          <Box  marginBottom={2}>
            <h4 className='section-h3 text-[18pt] uppercase font-black' >
							{title}
						</h4>
						<p className="text-[7pt] font-bold">
							{subtitle}
						</p>
						<p className="text-[7pt] font-extralight">
							{subDetail}
						</p>
          </Box>
          <Box>
            <p className="mt-2 text-[7pt] font-extralight">
              {detail}
            </p>
            <button onClick={() => {onClickExplore(0)}} className="touch-click mt-8 py-2 px-14 text-[14pt] tracking-wider">
              <span className="mix-blend-difference">
              Explore
              </span> 
            </button>
          </Box>
        </Grid>}
      </Grid>
    </Container>
  )
}