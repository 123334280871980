import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Footer } from "../../Components/Footer"
import { Header } from "../../Components/Header"

import BrandProd1 from "../../assets/new_prods/branding/BrandingSTCover1.png";
import BrandProd1Mobile from "../../assets/new_prods/branding/BrandingSTMCover1.png";
// import BrandProd1 from "../../assets/products/Branding/Branding-Nesod-2.png";
import BrandProd2 from "../../assets/new_prods/branding/BrandingSTITem1.png";
import BrandProd3 from "../../assets/new_prods/branding/BrandingSTItem2.png";

import AirProd1 from "../../assets/new_prods/branding/BrandingNDCover1.png";
import AirProd1Mobile from "../../assets/new_prods/branding/BrandingNDMCover2.png";
import AirProd2 from "../../assets/new_prods/branding/BrandingNDItem1.png";
import AirProd3 from "../../assets/new_prods/branding/BrandingNDItem2.png";

import NarrativeProd1 from '../../assets/new_prods/branding/BrandingTHCover1.png'
import NarrativeProd1Mobile from '../../assets/new_prods/branding/BrandingTHMCover1.png'
import NarrativeProd2 from '../../assets/new_prods/branding/BrandingTHItem1.png'
import NarrativeProd3 from '../../assets/new_prods/branding/BrandingTHItem2.png'

import laislaProd1 from "../../assets/services/3.png"
import laislaProd2 from "../../assets/services/2.png"
import laislaProd3 from "../../assets/services/1.png"

import ultimaProd1 from "../../assets/services/10164.png"
import ultimaProd2 from "../../assets/services/10165.png"
import ultimaProd3 from "../../assets/services/10166.png"
import "./services.css"
import { Box, Rating, Stack } from "@mui/material";
import { ServiceSection } from "../../Components/services/ServiceSection";
import { useLocation } from 'react-router-dom';

const brandProsData = [
  {
    tagName: 'NESODDBIENE',
    bannerImg: BrandProd1,
    mobileBanner: BrandProd1Mobile,
    leftImg: BrandProd2,
    rightImg: BrandProd3,
    brief: `Distinctive logo and branding for an established Norwegian honey producer, selling premium honey and maintaining a small local lot circa 2018. Committed to quality, we wanted to give them something up-to-date and on-trend.`,
    process: 'We distilled their passion and dedication into a distinctive logo, upscaled packaging, and a refined visual identity. The design reflects their craftsmanship and connection to the natural world, a contemporary yet authentic aesthetic.',
    process1: '',
    review: `“laconic brilliantly captured Nesoddbiene's essence, crafting a logo and branding that radiates modernity and memorability, the design resonates with our passion for beekeeping. The distinctive identity perfectly mirrors our story, making Nesoddbiene stand out in the huge hive of honey producers! Exceptional work!”`,
    reviewUser: `Ronni. S`,
    reserved: false,
  },
  {
    tagName: 'Airquip',
    bannerImg: AirProd1,
    mobileBanner: AirProd1Mobile,
    leftImg: AirProd2,
    rightImg: AirProd3,
    brief: `Comprehensive branding for an industrial company specialising in compressed air systems, Airquip focused on establishing its identity apart from its parent company. Deliverables included a logo, brand mark, color palette, typography, and collateral; letterhead, uniforms, and business cards.`,
    process: 'The logo and brand mark were designed to emphasise simplicity and effectiveness, using clean lines and bold elements to convey reliability and innovation. A modern, approachable colour palette with balanced industrial tones provided a fresh appeal, supported by clear, adaptable typography.',
    process1: '',
    review: `“This company was amazing to work with! Great communication and amazing work.”`,
    reviewUser: `Bella S.`,
    reserved: true,
  },
  {
    tagName: 'Narrative Talent',
    bannerImg: NarrativeProd1,
    mobileBanner: NarrativeProd1Mobile,
    leftImg: NarrativeProd2,
    rightImg: NarrativeProd3,
    brief: `Amanda approached us for a logo and presentation for Narrative Talent, her PR & Communications talent acquisition company, with a promise to champion brands and curate services that attract, engage, hire, and retain the best PR & Communications talent in the Asia-Pacific region.`,
    process: 'We began by delving into her vision for Narrative Talent, ensuring the branding aligned with the holistic and industry-advocating approach. For the logo, we crafted a design that conveyed professionalism and connection, using modern typography and refined graphic elements symbolising collaboration and growth. We developed a clean, visually engaging layout with a cohesive color palette and tailored visual, every detail was fine-tuned to ensure Amanda’s messaging stood out with clarity and impact.',
    process1: '',
    review: `“Working with laconic on Narrative Talent's brand guidelines was effortless. With his attention to detail and creative design, he crafted a cohesive and visually stunning identity that perfectly captured our brand's essence.”`,
    reviewUser: `Amanda. V`,
    reserved: false,
  },
]

export const Branding = () => {
  let location = useLocation();

  useEffect(() => {
    if(location.hash){
      const viewElement = document.getElementById(`pageNum${location.hash}`)
      viewElement.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
    }else{
      window.scrollTo(0, 0)
    }
   }, [])

  return (
    <div className="service-pages bg-[#0e0e0e] pt-2 md:pt-4 lg:pt-4 pb-14 md:pb-32 lg:pb-32">
      <Header />
      {brandProsData.map((brandData, key) => (
        <ServiceSection 
        key={key}
        pageIndex={key}
        isScrollAnimation={key !== 0} reserved={brandData.reserved}
        mobileBanner={brandData.mobileBanner}
        bannerImg={brandData.bannerImg} prod1={brandData.leftImg} prod2={brandData.rightImg}
        tagName={brandData.tagName} brief={brandData.brief} process={brandData.process} 
        process1={brandData.process1} review={brandData.review} reviewuser={brandData.reviewUser}
        />
      ))}
      <div className='w-full py-20'></div>
      <Footer />
    </div>
  )
}